import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAIL,
  GET_CUSTOMER_VOUCHERS,
  GET_CUSTOMER_VOUCHERS_SUCCESS,
  GET_CUSTOMER_VOUCHERS_FAIL,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAIL,
  SEARCH_CUSTOMER,
  SEARCH_CUSTOMER_FAIL,
  SEARCH_CUSTOMER_SUCCESS,
  ACTIVATE_CUSTOMER,
  ACTIVATE_CUSTOMER_FAIL,
  ACTIVATE_CUSTOMER_SUCCESS,
  DEACTIVATE_CUSTOMER,
  DEACTIVATE_CUSTOMER_FAIL,
  DEACTIVATE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_FAIL,
  DELETE_CUSTOMER_SUCCESS
} from './constants';

const initialState = {
  loading: false,
  customer: {},
  customers: {
    data: [],
    meta: {}
  },
  vouchers: {
    data: [],
    meta: {}
  },
  searchString: ''
};

function customerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMER:
    case GET_CUSTOMER_VOUCHERS:
    case UPDATE_CUSTOMER:
    case SEARCH_CUSTOMER:
    case ACTIVATE_CUSTOMER:
    case DEACTIVATE_CUSTOMER:
    case DELETE_CUSTOMER:
    case 'ISSUE_REWARDS':
    case GET_CUSTOMERS:
      let payload = action.payload || {};
      console.log(payload);
      return {
        ...state,
        loading: true,
        searchString: payload.hasOwnProperty('search_string') ? payload.search_string : ''
      };
    case GET_CUSTOMERS_SUCCESS:
    case SEARCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        customers: {
          data: action.payload.data,
          meta: action.payload.meta
        }
      };
    case 'ISSUE_REWARDS_SUCCESS':
    case GET_CUSTOMERS_FAIL:
    case GET_CUSTOMER_FAIL:
    case GET_CUSTOMER_VOUCHERS_FAIL:
    case UPDATE_CUSTOMER_FAIL:
    case ACTIVATE_CUSTOMER_FAIL:
    case DEACTIVATE_CUSTOMER_FAIL:
    case DELETE_CUSTOMER_FAIL:
    case SEARCH_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false
      };
    case GET_CUSTOMER_SUCCESS:
    case ACTIVATE_CUSTOMER_SUCCESS:
    case DEACTIVATE_CUSTOMER_SUCCESS:
    case DELETE_CUSTOMER_SUCCESS:
    case UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        customer: action.payload.data
      };
    case GET_CUSTOMER_VOUCHERS_SUCCESS:
      return {
        ...state,
        loading: false,
        vouchers: {
          data: action.payload.data,
          meta: action.payload.meta
        }
      };
    default:
      return state;
  }
}

export default customerReducer;
