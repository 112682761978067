import React, { useState, useMemo, useEffect } from 'react';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import { Alert, Modal, Table, TableProps, Tabs, message } from 'antd';
import { TableMainText, TableSecondaryText } from 'styles';

import { useRewardList } from 'api/rewards';
import { FEATURES, SMALL_PAGE_SIZE } from 'appConstants';
import { TableTimestamp, SearchBar, Balance } from 'components';
import { useFeatureFlags, useListState } from 'hooks';
import API from 'utils/request';

interface RewardSelectModalProps {
  isOutcome?: boolean;
  queryParams?: object;
  chooseMultiple?: boolean;
  selectedRowKeys?: number[];
  selectedRows?: any[];
  tableProps?: TableProps<any>;
  warningText?: string;
  showWarning?: boolean;
  showSystemRewards?: boolean;
  tabsToShowWarning?: string[];
  isReportModal?: boolean;
}

const columns = [
  {
    title: 'Reward',
    key: 'name',
    render: (_, record: any) => (
      <>
        <TableMainText>{record.name}</TableMainText>
        <TableSecondaryText>ID: {record.id}</TableSecondaryText>
      </>
    )
  },
  {
    title: 'Merchant',
    dataIndex: 'merchant_name',
    key: 'merchant_name',
    width: 300,
    render: (_, record) => <span style={{ wordBreak: 'break-all' }}>{record['merchant_name']}</span>
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    width: 150,
    render: (_, record) => (
      <Balance
        current={record?.['lifetime_rewards_available']}
        total={record?.['lifetime_max_rewards']}
      />
    )
  },
  {
    title: 'Selling from',
    dataIndex: 'begins_at',
    key: 'begins_at',
    width: 150,
    render: (_, record) => <TableTimestamp timestamp={record['begins_at']} />
  },
  {
    title: 'Selling to',
    dataIndex: 'ends_at',
    key: 'ends_at',
    width: 150,
    sorter: true,
    render: (_, record) => <TableTimestamp timestamp={record['ends_at']} />
  }
];

interface DeleteCommentModalProps {
  recordId: number;
}

export const DeleteRewardModal = NiceModal.create(({ recordId }: DeleteCommentModalProps) => {
  const modal = useModal();

  const handleDeleteComment = async e => {
    e.preventDefault();
    try {
      await API.delete(`/v4/dash/customer_requests/${recordId}`);
      message.info('Request has been cancelled');
    } catch (e) {
      message.error(e?.response?.data?.message || 'Something Went Wrong');
    } finally {
      modal.hide();
    }
  };

  return (
    <Modal
      title={'Submit deletion request?'}
      okText={'Request delete'}
      open={modal.visible}
      onOk={handleDeleteComment}
      onCancel={modal.hide}
      afterClose={modal.remove}
      okButtonProps={{ danger: true }}
      maskClosable={false}
    >
      This reward is pending approval. Are you sure you want to delete it?
      <br />
      <br />
      Once the deletion is approved, this entry will not be available and you will need to issue a
      reward again.
    </Modal>
  );
});

const RewardSelectModal = NiceModal.create(
  ({
    showSystemRewards = false,
    isOutcome = false,
    queryParams = {},
    chooseMultiple = false,
    selectedRowKeys,
    selectedRows,
    tableProps,
    showWarning = false,
    tabsToShowWarning = [],
    warningText,
    isReportModal
  }: RewardSelectModalProps) => {
    const modal = useModal();
    const [selectedRewardIds, setSelectedRewardIds] = useState(selectedRowKeys);
    const [, setSelectedRewards] = useState(selectedRows);

    const { handleSearch, handleStateChange, handleTableChange, pagination, params } = useListState(
      {
        state: 'active',
        size: SMALL_PAGE_SIZE
      }
    );

    const featureFlags = useFeatureFlags();
    const privateRewardsEnabled = featureFlags[FEATURES.PRIVATE_REWARDS.key];

    const filterQueryParams = useMemo(() => {
      const query = JSON.parse(JSON.stringify(queryParams));
      if (params.state === 'system') delete query.type;
      return query;
    }, [queryParams, params.state]);

    const { data, meta, isLoading } = useRewardList({ ...filterQueryParams, ...params });

    useEffect(() => {
      setSelectedRewardIds(selectedRowKeys);
      setSelectedRewards(selectedRows);
    }, [selectedRowKeys, selectedRows]);

    const onRowSelectionChange = (selectedRowKeys: number[], selectedRows) => {
      if (chooseMultiple) {
        setSelectedRewardIds(selectedRowKeys);
        setSelectedRewards(selectedRows);
      }
    };

    const handleOk = () => {
      if (chooseMultiple) {
        modal.resolve(selectedRewardIds);
        // Maybe need to submit a list of rewards?
        // modal.resolve(selectedRewards);
        modal.hide();
      } else {
        modal.hide();
      }
    };

    const shouldSubmitOnRowClick = !chooseMultiple;

    let tabItems: any[];

    if (isReportModal) {
      tabItems = [
        { label: 'Active', key: 'active' },
        { label: 'Ended', key: 'ended' },
        { label: 'Inactive', key: 'inactive' },
        { label: 'Draft', key: 'draft' },
        { label: 'Approved', key: 'approved' },
        ...(privateRewardsEnabled ? [{ label: 'Private', key: 'private' }] : []),
        { label: 'System', key: 'system' }
      ];
    } else if (isOutcome) {
      tabItems = [
        { label: 'Active', key: 'active' },
        { label: 'Approved', key: 'approved' },
        { label: 'System', key: 'system' }
      ];
    } else {
      tabItems = [
        { label: 'All', key: 'all' },
        { label: 'Active', key: 'active' },
        { label: 'Approved', key: 'approved' },
        ...(privateRewardsEnabled ? [{ label: 'Private', key: 'private' }] : []),
        ...(showSystemRewards ? [{ label: 'System', key: 'system' }] : [])
      ];
    }

    return (
      <Modal
        {...antdModalV5(modal)}
        maskClosable={false}
        width={1200}
        title="Select a reward"
        onOk={handleOk}
        okText="Add reward"
        cancelText="Cancel"
        okButtonProps={shouldSubmitOnRowClick ? { style: { display: 'none' } } : null}
      >
        <SearchBar
          onSearch={handleSearch}
          placeholder="Find a reward by name, id or merchant"
          style={{ marginBottom: '1rem' }}
          data-testid="reward-select-searchbar"
        />
        <Tabs
          activeKey={!params.state ? 'all' : params.state}
          onChange={handleStateChange}
          tabBarStyle={{ marginBottom: 0 }}
          data-testid="reward-tabs"
          items={tabItems}
        />
        {tabsToShowWarning.includes(params.state) && showWarning && (
          <Alert
            message=""
            description={warningText}
            type="warning"
            showIcon
            style={{ marginBottom: '1rem' }}
          />
        )}
        <Table<any>
          rowKey={c => c.id}
          loading={isLoading}
          onRow={(record, _) => ({
            onClick: () => {
              if (shouldSubmitOnRowClick) {
                modal.resolve(record);
                modal.hide();
              }
            },
            style: { cursor: shouldSubmitOnRowClick ? 'pointer' : 'auto' }
          })}
          rowSelection={
            chooseMultiple
              ? {
                  ...(selectedRewardIds &&
                    Array.isArray(selectedRewardIds) && { selectedRowKeys: selectedRewardIds }),
                  onChange: onRowSelectionChange
                }
              : null
          }
          columns={columns}
          dataSource={data}
          onChange={handleTableChange}
          showHeader
          pagination={{
            ...pagination,
            showQuickJumper: true,
            total: meta?.count || 0
          }}
          {...tableProps}
        />
      </Modal>
    );
  }
);

export default RewardSelectModal;
