import useSWR from 'swr';

import { API_ROOT, PERX_CONNECT } from 'appConstants';
import API from 'utils/request';

const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${PERX_CONNECT.SECRET_KEY}`,
  'OpenAI-Beta': 'assistants=v2'
};

export const createMessage = async (message: string) => {
  // get value of THREAD_ID from local storage
  const THREAD_ID = localStorage.getItem('THREAD_ID');
  if (!THREAD_ID) {
    const createThread = await API.post(`https://api.openai.com/v1/threads`, {}, { headers });
    localStorage.setItem('THREAD_ID', createThread.data.id);
  }

  const MESSAGE_URL = `https://api.openai.com/v1/threads/${localStorage.getItem(
    'THREAD_ID'
  )}/messages`;
  const RUN_URL = `https://api.openai.com/v1/threads/${localStorage.getItem('THREAD_ID')}/runs`;

  await API.post(MESSAGE_URL, { role: 'user', content: message }, { headers });
  await API.post(RUN_URL, { assistant_id: PERX_CONNECT.ASSISTANT_ID }, { headers });
};

const fetcher = async (url, headers) => {
  const response = await fetch(url, { headers });
  return response.json();
};

export const useMessages = () => {
  const THREAD_ID = localStorage.getItem('THREAD_ID');
  const URL = THREAD_ID
    ? `https://api.openai.com/v1/threads/${THREAD_ID}/messages?order=asc`
    : null;
  const { data, isLoading, mutate } = useSWR(URL, url => fetcher(url, headers));

  return { data, isLoading, mutate };
};

export const deleteThread = async () => {
  const THREAD_ID = localStorage.getItem('THREAD_ID');
  if (THREAD_ID) {
    await API.delete(`https://api.openai.com/v1/threads/${THREAD_ID}`, { headers });
    localStorage.removeItem('THREAD_ID');
  }
};
