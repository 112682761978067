import { PROJECT_NAME } from 'containers/App/constants';

const CONTAINER_NAME = 'Customer';

export const GET_CUSTOMERS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CUSTOMERS`;
export const GET_CUSTOMERS_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CUSTOMERS_SUCCESS`;
export const GET_CUSTOMERS_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CUSTOMERS_FAIL`;

export const GET_CUSTOMER = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CUSTOMER`;
export const GET_CUSTOMER_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CUSTOMER_SUCCESS`;
export const GET_CUSTOMER_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CUSTOMER_FAIL`;

export const GET_CUSTOMER_VOUCHERS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CUSTOMER_VOUCHERS`;
export const GET_CUSTOMER_VOUCHERS_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CUSTOMER_VOUCHERS_SUCCESS`;
export const GET_CUSTOMER_VOUCHERS_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CUSTOMER_VOUCHERS_FAIL`;

export const UPDATE_CUSTOMER = `${PROJECT_NAME}/${CONTAINER_NAME}/UPDATE_CUSTOMER`;
export const UPDATE_CUSTOMER_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/UPDATE_CUSTOMER_SUCCESS`;
export const UPDATE_CUSTOMER_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/UPDATE_CUSTOMER_FAIL`;

export const SEARCH_CUSTOMER = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_CUSTOMER`;
export const SEARCH_CUSTOMER_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_CUSTOMER_SUCCESS`;
export const SEARCH_CUSTOMER_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_CUSTOMER_FAIL`;

export const ACTIVATE_CUSTOMER = `${PROJECT_NAME}/${CONTAINER_NAME}/ACTIVATE_CUSTOMER`;
export const ACTIVATE_CUSTOMER_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/ACTIVATE_CUSTOMER_SUCCESS`;
export const ACTIVATE_CUSTOMER_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/ACTIVATE_CUSTOMER_FAIL`;

export const DEACTIVATE_CUSTOMER = `${PROJECT_NAME}/${CONTAINER_NAME}/DEACTIVATE_CUSTOMER`;
export const DEACTIVATE_CUSTOMER_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/DEACTIVATE_CUSTOMER_SUCCESS`;
export const DEACTIVATE_CUSTOMER_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/DEACTIVATE_CUSTOMER_FAIL`;

export const DELETE_CUSTOMER = `${PROJECT_NAME}/${CONTAINER_NAME}/DELETE_CUSTOMER`;
export const DELETE_CUSTOMER_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/DELETE_CUSTOMER_SUCCESS`;
export const DELETE_CUSTOMER_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/DELETE_CUSTOMER_FAIL`;
