import React, { useState, useRef, useEffect } from 'react';
import {
  DownOutlined,
  ArrowUpOutlined,
  InfoCircleOutlined,
  LikeFilled,
  DislikeFilled
} from '@ant-design/icons';
import { Button, Space, Modal, Input, Tag, Spin, Tooltip } from 'antd';
import { marked } from 'marked';

import { PERX_CONNECT } from 'appConstants';

import { createMessage, useMessages, deleteThread } from './api';
import sparkImage from './stars_2426430.png';
import './ChatBot.css';
import Arrow from 'assets/images/icon-arrow.svg';

const App: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [thread, setThread] = useState([]);
  const [preloadedQuestions, setPreloadedQuestions] = useState(
    PERX_CONNECT.PRELOADED_QUESTIONS as string[]
  );
  const threadEndRef = useRef<HTMLDivElement>(null);
  const { data, isLoading, mutate } = useMessages();

  const scrollToBottom = () => {
    if (threadEndRef.current) threadEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [thread]);

  useEffect(() => {
    if (!data?.data?.length) return;
    setThread(data.data);
  }, [data]);

  useEffect(() => {
    if (!open) return;

    const interval = setInterval(() => {
      mutate();
    }, 2000);

    return () => clearInterval(interval);
  }, [open]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!inputValue) return;

    setPreloadedQuestions([]);
    await createMessage(inputValue);
    mutate();
    setInputValue('');
  };

  const footer = [
    <>
      <span style={{ fontSize: '10px' }}>
        Use of Connect+ Expert is subject to our <a href="#">Terms of Service</a> and{' '}
        <a href="#">Privacy Policy</a>
      </span>
      {thread.length > 0 && (
        <span style={{ fontSize: '14px' }}>
          Was this answer helpful?
          <a href="#">
            <LikeFilled />
          </a>
          <a href="#">
            <DislikeFilled />
          </a>
        </span>
      )}
    </>
  ];

  const showModal = () => {
    setOpen(true);
  };

  const closeModal = async () => {
    setOpen(false);
    setThread([]);
    setPreloadedQuestions(PERX_CONNECT.PRELOADED_QUESTIONS);
    await deleteThread();
  };

  const title = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div>
          <span>Connect+ Expert</span>
          <Tag color="#108ee9" style={{ marginLeft: '8px', fontSize: '10px', height: '20px' }}>
            BETA
          </Tag>
        </div>
        <div
          style={{
            border: '1px solid #FFCA95',
            borderRadius: '5px',
            padding: '0 5px',
            marginRight: '20px'
          }}
        >
          <Tooltip
            placement="bottom"
            title={'Token limits renew daily. Become a Perx exclusive member for unlimited tokens'}
          >
            <InfoCircleOutlined
              style={{ cursor: 'pointer', marginRight: '10px', color: '#FFCA95' }}
            />
          </Tooltip>
          <span style={{ fontWeight: 'normal' }}>50/100 tokens left</span>
        </div>
      </div>
    );
  };

  return (
    <>
      {/*  */}
      <Space>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Button
            type="primary"
            onClick={showModal}
            style={{
              backgroundColor: '#108ee9',
              border: 'none',
              borderRadius: '0 0 10px 10px',
              height: '26px',
              padding: 'revert',
              display: 'flex',
              alignItems: 'center',
              gap: '5px'
            }}
          >
            <img
              src={sparkImage}
              alt="spark"
              style={{
                width: '20px',
                height: '20px'
              }}
            />
            <span> Have a question about using Perx Connect+? </span>
          </Button>
          <DownOutlined
            onClick={showModal}
            style={{
              color: '#108ee9',
              fontSize: '15px'
            }}
          />
        </div>
      </Space>
      <Modal
        title={title()}
        open={open}
        width={700}
        style={{
          top: 0
        }}
        onCancel={closeModal}
        footer={footer}
      >
        <div>
          {/* THREAD CHAT */}
          <div
            style={{
              padding: '10px 15px',
              maxHeight: '500px',
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
          >
            {!thread.length && isLoading && (
              <div style={{ textAlign: 'right' }}>
                <Spin />
              </div>
            )}
            {thread.map(message => {
              const content =
                message.content.length > 0 ? marked(message.content[0]['text']['value']) : '';
              return (
                <div
                  key={message.id}
                  style={{
                    textAlign: message.role === 'user' ? 'right' : 'left'
                  }}
                >
                  {content === '' ? (
                    <Spin size="small" />
                  ) : (
                    <div
                      id="marked-gpt-content"
                      style={{
                        display: 'inline-block',
                        margin: '10px 0',
                        padding: '5px 10px',
                        borderRadius: '10px',
                        backgroundColor: message.role === 'user' ? '#e6f7ff' : '#f0f0f0'
                      }}
                      dangerouslySetInnerHTML={{ __html: `${content}` }}
                    ></div>
                  )}
                </div>
              );
            })}
            <div ref={threadEndRef}></div>
          </div>

          {/* INPUT */}
          <form onSubmit={handleSubmit}>
            <Input
              placeholder="Ask me anything about Perx! eg: How to setup stamp campaigns?"
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              style={{
                fontSize: 'small',
                borderRadius: '10px'
                // marginBottom: '12px'
              }}
              prefix={
                <img
                  src={sparkImage}
                  alt="spark"
                  style={{
                    width: '20px',
                    height: '20px',
                    marginRight: '8px'
                  }}
                />
              }
              suffix={
                <Button
                  disabled={!inputValue}
                  onClick={handleSubmit}
                  style={{
                    padding: '0 5px',
                    height: '24px',
                    borderRadius: '8px',
                    backgroundColor: !inputValue ? 'lightgray' : 'dodgerblue',
                    color: 'white'
                  }}
                >
                  <ArrowUpOutlined />
                </Button>
              }
            />
          </form>

          {/* PRELOADED QUESTIONS */}
          {!thread.length && (
            <div
              style={{
                padding: '10px 0',
                maxHeight: '500px',
                overflowY: 'auto',
                overflowX: 'hidden'
              }}
            >
              {preloadedQuestions.map((q, i) => {
                return (
                  <p
                    key={i}
                    style={{
                      cursor: 'pointer',
                      padding: '10px',
                      borderRadius: '5px',
                      backgroundColor: '#F7F7F9',
                      width: 'fit-content'
                    }}
                    onMouseEnter={e => (e.currentTarget.style.backgroundColor = '#E9E9EC')}
                    onMouseLeave={e => (e.currentTarget.style.backgroundColor = '#F7F7F9')}
                    onMouseDown={e => {
                      setInputValue(q);
                    }}
                    onMouseUp={e => {
                      handleSubmit(e);
                    }}
                  >
                    <img src={Arrow} alt="arrow" style={{ marginRight: '5px' }} />
                    {q}
                  </p>
                );
              })}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default App;
