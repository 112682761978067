import React, { useReducer, useEffect, useCallback, memo, useState } from 'react';
import { Tag } from 'antd';
import { Modal } from 'antd';
import { Tabs } from 'antd';
import produce from 'immer';
import _capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import { TableMainText, TableSecondaryText } from 'styles';

import { SMALL_PAGE_SIZE } from 'appConstants';
import { TableTimestamp, SearchBar, StatusTag } from 'components';
import { TableWithNoWrapHeader } from 'components/StyledTable';
import { IAnyCampaign } from 'containers/Campaign/types';
import { useAxios } from 'hooks';
import { IPaginatedResponse } from 'utils/types';

const useCampaigns = params =>
  useAxios<IPaginatedResponse<IAnyCampaign>>('/v4/dash/campaigns', { params });

interface CampaignSelectModalState {
  state: any;
  page: any;
  order: any;
  size: any;
  sort_by: any;
  search_string: any;
  campaign_type: any;
  eligible_user_account_id: any;
  filter_not_completed: boolean;
}
const paramsReducer = (state: CampaignSelectModalState, action): CampaignSelectModalState =>
  produce(state, draft => {
    switch (action.type) {
      case 'CAMPAIGN_TYPE':
        if (action.value === 'quest') {
          draft['campaign_type'] = 'quest';
          draft['state'] = 'active';
        } else if (action.value === 'progress') {
          draft['campaign_type'] = 'progress';
          draft['state'] = 'active';
        } else if (action.value === 'quiz' || action.value === 'survey') {
          draft['campaign_type'] = 'game';
          draft['game_type'] = action.value;
          draft['state'] = null;
        } else {
          draft['campaign_type'] = action.value;
          draft['state'] = null;
          delete draft['game_type'];
        }
        break;
      case 'STATE':
        draft.state = action.value === 'all' ? null : action.value;
        break;
      case 'TABLE':
        draft.page = action.pagination;
        if (Object.keys(action.sorter).length) {
          draft['sort_by'] = action.sorter.columnKey;
          draft.order = action.sorter.order === 'ascend' ? 'asc' : 'desc';
        }
        break;
      case 'PAGINATION':
        draft.page = action.current;
        if (action.size) {
          draft.size = action.size;
        }

        break;
      case 'SEARCH':
        draft['search_string'] = action.value;
        break;
      case 'FILTER_NOT_COMPLETED':
        draft.filter_not_completed = action.value;
        if (action.value) {
          draft.state = ['active', 'draft'];
        } else {
          draft.state = null;
        }
        break;
      default:
        break;
    }
  });

const CampaignSelectModal = ({
  isOpen = false,
  closeModal,
  onChooseCampaign,
  campaignType = null,
  hasMultiRowSelect = false,
  tabs,
  eligableUserAccountId = null,
  alreadyChosenCampaignsKeys = [],
  otherParams = {}
}) => {
  const [selectedCampaignKeys, setSelectedCampaignKeys] = useState(alreadyChosenCampaignsKeys);
  const [params, paramsDispatch] = useReducer(paramsReducer, {
    state: !tabs ? null : tabs[0] === 'all' ? null : tabs[0],
    page: 1,
    order: 'asc',
    sort_by: null,
    search_string: '',
    size: SMALL_PAGE_SIZE,
    campaign_type: campaignType,
    eligible_user_account_id: eligableUserAccountId,
    filter_not_completed: false,
    ...otherParams
  });
  const [loading, response] = useCampaigns(params);

  const onChange = pagination => {
    paramsDispatch({ type: 'PAGINATION', current: pagination.current });
  };

  const onTabChange = tab => paramsDispatch({ type: 'STATE', value: tab });

  const onSelectCampaignChange = useCallback(selectedCampaignKeys => {
    setSelectedCampaignKeys(selectedCampaignKeys);
  }, []);

  useEffect(() => {
    paramsDispatch({ type: 'CAMPAIGN_TYPE', value: campaignType });
  }, [campaignType]);

  useEffect(() => {
    if (otherParams['state']) {
      paramsDispatch({ type: 'STATE', value: otherParams['state'] });
    }
  }, [otherParams]);

  return (
    <Modal
      maskClosable={false}
      width={1200}
      title="Select a Campaign"
      open={isOpen}
      onOk={
        hasMultiRowSelect
          ? () => {
              onChooseCampaign(selectedCampaignKeys);
            }
          : closeModal
      }
      onCancel={closeModal}
      okText="Add Campaign"
      cancelText="Cancel"
    >
      <div data-testid="CampaignSelectModal">
        <SearchBar
          //@ts-ignore
          onSearch={value => paramsDispatch({ type: 'SEARCH', value })}
          placeholder="Find a campaign by name, type or merchant"
          style={{ marginBottom: '0.5rem' }}
        />
        {tabs && (
          <Tabs
            activeKey={!params.state ? 'all' : params.state}
            onChange={onTabChange}
            tabBarStyle={{ marginBottom: 0 }}
            items={tabs.map(tab => ({ key: tab, label: _capitalize(tab) }))}
          />
        )}
        <TableWithNoWrapHeader
          scroll={{ y: 430 }}
          rowKey={'id'}
          loading={loading}
          columns={[
            {
              title: 'Name',
              key: 'name',
              render: (_, record: IAnyCampaign) => (
                <>
                  <TableMainText>{record.name}</TableMainText>
                  <TableSecondaryText>ID: {record.id}</TableSecondaryText>
                </>
              )
            },
            {
              title: 'Status',
              key: 'state',
              dataIndex: 'state',
              render: state => <StatusTag state={state} />
            },
            {
              title: 'Tags',
              key: 'tags',
              render: (_, record: IAnyCampaign) => (
                <>
                  {record.tags.map(tag => (
                    <Tag key={tag.id}>{tag.name}</Tag>
                  ))}
                </>
              )
            },
            {
              title: 'Start Date',
              key: 'start_date',
              render: (_, record) => <TableTimestamp timestamp={record['start_date']} />
            },
            {
              title: 'End Date',
              key: 'end_date',
              render: (_, record) => <TableTimestamp timestamp={record['end_date']} />
            }
          ]}
          dataSource={
            otherParams['state'] === 'active,inactive,approved'
              ? response?.data || []
              : response?.data.filter(campaign => ['active', 'draft'].includes(campaign.state)) ||
                []
          }
          onChange={onChange}
          onRow={
            !hasMultiRowSelect
              ? (record, _) => ({
                  onClick: () => {
                    onChooseCampaign(record);
                    closeModal();
                  }
                })
              : null
          }
          rowSelection={
            hasMultiRowSelect
              ? {
                  selectedRowKeys: selectedCampaignKeys,
                  onChange: onSelectCampaignChange
                }
              : null
          }
          showHeader
          pagination={{
            showSizeChanger: true,
            onShowSizeChange: (current, size) =>
              paramsDispatch({ type: 'PAGINATION', current, size }),
            showQuickJumper: true,
            current: params.page,
            position: ['bottomRight'],
            defaultPageSize: SMALL_PAGE_SIZE,
            total: response?.meta?.['count'] || 0
          }}
        />
      </div>
    </Modal>
  );
};

CampaignSelectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hasMultiRowSelect: PropTypes.bool,
  onChooseCampaign: PropTypes.func,
  campaignType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOf([
        'stamp',
        'game',
        'invite',
        'give_reward',
        'quiz',
        'survey',
        'referral',
        'progress'
      ])
    )
  ]),
  tabs: PropTypes.array,
  eligableUserAccountId: PropTypes.number,
  eventName: PropTypes.string
};

export default memo(CampaignSelectModal);
