import React, { useReducer } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Card, message } from 'antd';
import { Tabs } from 'antd';
import produce from 'immer';
import { TableMainText, TableSecondaryText } from 'styles';

import { MID_PAGE_SIZE } from 'appConstants';
import { TableActions, TableTimestamp } from 'components';
import { TableWithNoWrapHeader } from 'components/StyledTable';
import { PageContent, PageHeader } from 'components/page';
import { PartnerListResponse } from 'containers/Partners/types';
import { useAxios, usePermissions } from 'hooks';
import API, { downloadFile } from 'utils/request';

interface Props {}

const tabsList = [
  { key: 'all', label: 'All' }
  // hide the following two tabs until further notice
  // { key: 'active', label: 'Active' },
  // { key: 'inactive', label: 'Inactive' }
];

enum SortByEnum {
  ascend = 'ascend',
  descend = 'descend'
}

enum OrderEnum {
  asc = 'asc',
  desc = 'desc'
}

interface AudienceListPageState {
  page: number;
  size: number;
  sort_by?: string;
  search_string?: string;
  order?: OrderEnum;
  state: 'all';
}

const AudienceListPage = (props: Props) => {
  const [params, paramsDispatch] = useReducer(
    (state: AudienceListPageState, action): AudienceListPageState =>
      produce(state, draft => {
        switch (action.type) {
          case 'SEARCH':
            draft.search_string = action.value;
            break;
          case 'TABLE': {
            draft.page = action.pagination;
            draft.sort_by = action.sorter.columnKey;
            draft.order =
              action.sorter.order === SortByEnum.ascend ? OrderEnum.asc : OrderEnum.desc;

            return draft;
          }
          case 'STATE':
            draft.state = action.state === 'all' ? null : action.state;
            draft.page = 1;
        }
      }),
    {
      page: 1,
      size: MID_PAGE_SIZE,
      state: null,
      search_string: null,
      sort_by: 'created_at',
      order: OrderEnum.desc
    }
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'name',
      sorter: false,
      render: (_, record) => (
        <Link to={`/p/audiences/show/${record.id}`} style={{ display: 'inline-block' }}>
          <TableMainText>{record.name}</TableMainText>
          <TableSecondaryText>ID: {record.id}</TableSecondaryText>
        </Link>
      )
    },
    {
      title: 'Created At',
      key: 'created_at',
      dataIndex: 'created_at',
      sorter: true,
      render: (_, record) => <TableTimestamp timestamp={record.created_at} />
    },
    {
      title: 'Updated At',
      key: 'updated_at',
      dataIndex: 'updated_at',
      sorter: true,
      render: (_, record) => <TableTimestamp timestamp={record.updated_at} />
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => getActionsList(record),
      align: 'right' as 'right'
    }
  ];

  const { hasDraftPermission, canEdit } = usePermissions('audiences');

  const history = useHistory();

  const [loading, segments] = useAxios<PartnerListResponse>(`/v4/dash/v4_audiences`, {
    params
  });

  const onExport = record => {
    API.post(`/v4/dash/v4_audiences/${record.id}/export`)
      .then(() => {
        message.info('The exported file will be sent to your email.');
      })
      .catch(() => {
        message.error('Error exporting audience.');
      });
  };

  const onDownload = async record => {
    API.get(`/v4/dash/v4_audiences/${record.id}/download`, {
      responseType: 'blob'
    })
      .then((res: any) => {
        downloadFile(res.data, `${record.id}.csv`);
      })
      .catch(() => {
        message.error('Error downloading audience.');
      });
  };

  const getActionsList = record => {
    const actions = [];

    // edit is vislbe if user has edit permission OR user has draft permission and record is in draft state
    if (canEdit || (hasDraftPermission && record.state === 'draft')) {
      actions.push({
        key: 'edit',
        title: 'Edit',
        onClick: e => {
          e.stopPropagation();
          history.push(`/p/audiences/edit/${record.id}`);
        }
      });
      actions.push({
        key: 'export',
        title: 'Export',
        onClick: () => onExport(record)
      });
      actions.push({
        key: 'download',
        title: 'Download',
        onClick: () => onDownload(record)
      });
    }
    // only return table actions element if there are any actions to list
    return actions.length ? <TableActions actions={actions} /> : null;
  };

  const onTableChange = (pagination, filters, sorter) => {
    paramsDispatch({ type: 'TABLE', pagination: pagination.current, sorter: sorter });
  };

  return (
    <>
      <PageHeader
        title="Audiences"
        showPrimaryAction={hasDraftPermission || canEdit}
        primaryAction={{
          icon: <PlusOutlined />,
          onClick: () => history.push('/p/audiences/create'),
          label: 'Create New Audience'
        }}
      />
      <PageContent>
        <Card>
          {/* Hide until https://perxtechnologies.atlassian.net/browse/VS-7058
          will be completed
          <SearchBar
            onSearch={value => paramsDispatch({ type: 'SEARCH', value })}
            placeholder="Search rule by name or ID"
          /> */}
          <Tabs
            activeKey={!params.state ? 'all' : params.state}
            onChange={value => paramsDispatch({ type: 'STATE', state: value })}
            tabBarStyle={{ marginBottom: 0 }}
            items={tabsList.map(t => ({ label: t.label, key: t.key }))}
          />
          <TableWithNoWrapHeader<any>
            rowKey="id"
            columns={columns}
            dataSource={segments?.data || []}
            loading={loading}
            onChange={onTableChange}
            pagination={{
              position: ['bottomRight'],
              current: params.page,
              defaultPageSize: MID_PAGE_SIZE,
              pageSize: params.size,
              total: segments?.meta?.total_count || 0
            }}
          />
        </Card>
      </PageContent>
    </>
  );
};

export default AudienceListPage;
