import React, { memo } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { TableMainText, TableSecondaryText } from 'styles';

import { DATE_FORMAT, TIME_FORMAT, TIMEZONE_FORMAT } from 'appConstants';
import { useLocalization } from 'hooks';

const TableTimestamp: React.FC<{ timestamp: any; timezone?: string; rawText?: string }> = ({
  timestamp = null,
  timezone,
  rawText = null
}) => {
  const { timezone: tenantTimezone } = useLocalization();
  const timezoneToUse = timezone || tenantTimezone;

  if (timestamp || rawText) {
    const m = timestamp
      ? moment(timestamp)
      : moment(new Date(new Date().setHours(0, 0, 0, 0)).toISOString());
    if (!m.isValid()) {
      return <>'Invalid date'</>;
    }

    const dateInTimezone = timezoneToUse ? m.tz(timezoneToUse) : m;

    return (
      <div>
        <TableMainText>{!rawText ? dateInTimezone.format(DATE_FORMAT) : rawText}</TableMainText>
        <TableSecondaryText>
          {dateInTimezone.format(TIME_FORMAT)} {dateInTimezone.format(TIMEZONE_FORMAT)}
        </TableSecondaryText>
      </div>
    );
  }
  return <>'-'</>;
};

TableTimestamp.propTypes = {
  timestamp: PropTypes.string
};

export default memo(TableTimestamp);
